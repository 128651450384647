import { Link } from 'react-router-dom';
import logo from '../assets/images/color-wash-logo-vertical-white.png';

export default function Exit() {
  return (
    <div>
      <img
        className="w-2/3 max-w-md ml-auto mr-auto pt-12"
        src={logo}
        alt="Colorwash Logo"
      />
      <div className="mt-32 ml-8 mr-8 text-center flex flex-col gap-32">
        <div>
          <h1 className="text-4xl font-bold ml-8 mr-8">Thanks for visiting!</h1>

          <p className="mt-8">We hope you had fun!</p>
        </div>
        <div>
          <h1 className="text-2xl font-bold ml-8 mr-8 my-8">
            How was your experience with Colorwash? We'd love to hear from you!
          </h1>
          <Link to={`/feedback`}>
            <button className="w-full bg-primary hover:bg-primary-600 text-white py-2 px-4 rounded">
              Give Feedback
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
