import logo from '../assets/images/color-wash-logo-vertical-white.png';

export default function Home() {
  return (
    <div>
      <img
        className="w-2/3 max-w-md ml-auto mr-auto pt-12"
        src={logo}
        alt="Colorwash Logo"
      />
      <div className="mt-32 ml-8 mr-8 text-center">
        <h1 className="text-4xl font-bold ml-8 mr-8">Welcome to Colorwash</h1>

        <p className="mt-8">
          Scan the QR Code posted at your favorite carwash to get started!
        </p>
      </div>
    </div>
  );
}
