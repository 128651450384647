import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Go from './routes/Go';
import Control from './routes/Control';
import Home from './routes/Home';
import Exit from './routes/Exit';
import Feedback from './routes/Feedback';
import TokenProvider from './context/TokenContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TokenProvider>
        <Routes>
          <Route path="/:deviceUrl" element={<Go />} />

          <Route path="/control/:deviceUrl" element={<Control />}></Route>

          <Route path="/exit" element={<Exit />} />

          <Route path="/feedback" element={<Feedback />} />

          <Route path="*" element={<Home />} />
        </Routes>
      </TokenProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
