import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import logo from '../assets/images/color-wash-logo-vertical-white.png';
import { TokenContext } from '../context/TokenContext';

export default function Go() {
  const { deviceUrl } = useParams();
  const [info, setInfo] = useState<any>();
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [ , setToken] = useContext(TokenContext);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/customer-control/app/${deviceUrl}/info`,
    ).then(async (res) => {
      if (res.ok) {
        setInfo(await res.json());
      } else {
        setError(true);
      }
      setInfoLoaded(true);
    });
  }, [deviceUrl]);

  const generateToken = () => {
    const localToken = JSON.parse(
      window.localStorage.getItem('ccToken') ?? '{}',
    );

    if (localToken && localToken.token && localToken.issued) {
      let timeSinceIssued = Date.now() - localToken.issued;
      if (timeSinceIssued < 15 * 60 * 1000) {
        setToken({
          token: localToken.token,
          issued: localToken.issued
        })
        return;
      } else {
        window.localStorage.removeItem('ccToken');
      }
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/customer-control/app/${deviceUrl}/token`,
    )
      .then((res) => res.json())
      .then((res) => {
        const token = res.access_token;
        setToken({
          token: token,
          issued: Date.now()
        });
        window.localStorage.setItem(
          'ccToken',
          JSON.stringify({
            token,
            issued: Date.now(),
          }),
        );
      });
  }

  return (
    <div>
      <img
        className="w-2/3 max-w-md ml-auto mr-auto pt-12"
        src={logo}
        alt="Colorwash Logo"
      />
      {error && (
        <div className="mt-32 ml-8 mr-8 text-center">
          <h1 className="text-center text-4xl">
            Sorry! Colorwash is offline at the moment.
          </h1>
        </div>
      )}
      {infoLoaded && !error && (
        <div className="mt-32 ml-8 mr-8 text-center">
          <h1 className="text-4xl font-bold ml-8 mr-8">
            Welcome to <br /> {info?.orgName}!
          </h1>
          {info?.enabled ? (
            <>
              <p className="mt-8">
                Tap the button below to begin your own Colorwash Experience
              </p>
              <div className="mt-8">
                <Link to={`/control/${deviceUrl}`} onClick={generateToken}>
                  <button className="w-full bg-primary hover:bg-primary-600 text-white py-2 px-4 rounded">
                    I'm Ready
                  </button>
                </Link>
              </div>
            </>
          ) : (
            <p>Customer Control is currently disabled.</p>
          )}
        </div>
      )}
    </div>
  );
}
