import { FormEvent, useState } from 'react';
import logo from '../assets/images/color-wash-logo-vertical-white.png';

export default function Feedback() {
  const [submitted, setSubmitted] = useState(false);

  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const localToken = JSON.parse(
      window.localStorage.getItem('ccToken') ?? '{}',
    );
    console.log({ rating, feedbackText, email });
    await fetch(
      process.env.REACT_APP_BACKEND_URL + `/customer-control/app/feedback`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localToken.token}`,
        },
        method: 'POST',
        body: JSON.stringify({ rating, feedbackText, email }),
      },
    );
    setSubmitted(true);
  };

  if (submitted)
    return (
      <div>
        <img
          className="w-2/3 max-w-md ml-auto mr-auto pt-12"
          src={logo}
          alt="Colorwash Logo"
        />
        <div className="mt-8">
          <h1 className="text-4xl font-bold text-center">
            Thanks for your feedback!
          </h1>
        </div>
      </div>
    );

  return (
    <div>
      <img
        className="w-2/3 max-w-md ml-auto mr-auto pt-12"
        src={logo}
        alt="Colorwash Logo"
      />
      <div className="mt-8">
        <h1 className="text-4xl font-bold text-center">
          We welcome your feedback!
        </h1>

        <form
          className="mt-8 mx-4 flex flex-col gap-6 text-xl"
          onSubmit={handleSubmit}
        >
          <div className="bg-black bg-opacity-60 p-4 rounded-lg">
            <label>
              Rate your experience with Colorwash:
              <ul className="flex justify-between">
                {[1, 2, 3, 4, 5].map((n) => (
                  <li
                    key={n}
                    className="flex flex-col flex-grow text-center p-4"
                  >
                    <input
                      type={'radio'}
                      id={`rating${n}`}
                      name="rating"
                      value={n}
                      onChange={() => setRating(n)}
                      required
                    />
                    <label htmlFor={`rating${n}`}>{n}</label>
                  </li>
                ))}
              </ul>
            </label>

            <label>
              Additional feedback (<em>optional</em>):
              <br />
              <textarea
                className="w-full text-black rounded-md mt-2 p-1"
                rows={4}
                maxLength={256}
                onChange={(e) => setFeedbackText(e.target.value)}
              />
            </label>

            <label>
              Email (<em>optional</em>):
              <br />
              <input
                type={'email'}
                className="text-black my-2 rounded-md p-1"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </label>
          </div>
          <input
            type={'submit'}
            value="Submit"
            className="w-full bg-primary hover:bg-primary-600 text-white py-2 px-4 rounded mt-4"
          />
        </form>
      </div>
    </div>
  );
}
