import { useEffect, useRef, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TokenContext } from '../context/TokenContext';

const rainbow = `linear-gradient(
  125deg,
  rgba(255, 0, 0, .8) 0%,
  rgba(255, 154, 0, .8) 10%,
  rgba(208, 222, 33, .8) 20%,
  rgba(79, 220, 74, .8) 30%,
  rgba(63, 218, 216, .8) 40%,
  rgba(47, 201, 226, .8) 50%,
  rgba(28, 127, 238, .8) 60%,
  rgba(95, 21, 242, .8) 70%,
  rgba(186, 12, 248, .8) 80%,
  rgba(251, 7, 217, .8) 90%,
  rgba(255, 0, 0, .8) 100%
)`;

function ShowCard({
  name,
  description,
  imageUrl,
  selected,
  onClick,
}: {
  name: string;
  description: string;
  imageUrl?: string;
  selected?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`p-4 h-32 rounded ${selected && 'border-2'}`}
      style={{
        backgroundColor: '',
        background: imageUrl
          ? `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${imageUrl})`
          : rainbow,
        backgroundSize: 'cover',
      }}
      onClick={onClick}
    >
      <h2 className="text-xl font-semibold pb-2">{name}</h2>
      <p>{description}</p>
    </div>
  );
}

function useInterval(callback: any, delay: any) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function ControlHeader({
  showName,
  hasControl,
}: {
  showName?: string;
  hasControl?: boolean;
}) {
  return (
    <div className="text-center text-xl relative">
      {hasControl ? (
        <h1>Currently Playing: {showName ? showName : 'No Show Selected'}</h1>
      ) : (
        <h1>You're up next!</h1>
      )}
    </div>
  );
}

export default function Control() {
  const { deviceUrl } = useParams();
  const [selectedShow, setSelectedShow] = useState<number | null>(null);
  const [status, setStatus] = useState('none');
  const [activeShow, setActiveShow] = useState('');
  const [shows, setShows] = useState<any>();
  const [{ token, issued }, setToken] = useContext(TokenContext);

  useInterval(() => {
    if (token === '') return;

    let timeSinceIssued = Date.now() - issued;
    if (timeSinceIssued >= 10 * 60 * 1000) {
      setStatus('expired');
      return;
    }

    fetch(process.env.REACT_APP_BACKEND_URL + `/customer-control/app/poll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((payload) => {
        setStatus(payload.status);
      });
  }, 1000);

  useEffect(() => {
    if (token === '') {
      const localToken = JSON.parse(
        window.localStorage.getItem('ccToken') ?? '{}',
      );
      if (localToken && localToken.token && localToken.issued) {
        let timeSinceIssued = Date.now() - localToken.issued;
        if (timeSinceIssued >= 10 * 60 * 1000) {
          setStatus('expired');
          return;
        }
        setToken({
          token: localToken.token,
          issued: localToken.issued,
        });
      }
    }
  }, [token, setToken]);

  useEffect(() => {
    if (!deviceUrl) return;
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/customer-control/app/${deviceUrl}/shows`,
    )
      .then((res) => res.json())
      .then((res) => {
        setShows(res);
      });
  }, [deviceUrl]);

  const sendShow = (show: number | null) => {
    if (show === null || status !== 'active') return;
    fetch(process.env.REACT_APP_BACKEND_URL + `/customer-control/app/start`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ showId: shows[show]._id }),
    }).then(() => {
      setActiveShow(shows[show].name);
    });
  };

  if (status === 'expired') window.location.replace('/exit');
  return (
    <div className="flex flex-col justify-between h-screen">
      <div className="mb-4 p-6 w-12/12 relative before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:opacity-60">
        <ControlHeader showName={activeShow} hasControl={status === 'active'} />
      </div>
      <h1 className="ml-4 text-2xl">
        {' '}
        {status === 'active'
          ? 'Select a Lightshow:'
          : 'Browse available shows:'}
      </h1>
      <div className="flex-grow ml-4 mr-4 overflow-scroll">
        <ul className="">
          {shows &&
            shows.map((show: any, i: number) => (
              <li
                key={i}
                className={
                  status !== 'active' ? 'blur-[1.5px] grayscale opacity-75' : ''
                }
              >
                <div className="mt-4 mb-4">
                  <ShowCard
                    {...show}
                    selected={selectedShow === i}
                    onClick={() => {
                      if (status === 'active') {
                        sendShow(i);
                        setSelectedShow(i);
                      }
                    }}
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>
      {/* <div className="pl-4 pr-4 pt-8 pb-8 w-full">
        <button
          className="w-full bg-primary disabled:bg-primary-700 hover:bg-primary-600 text-white text-xl py-2 px-4 rounded"
          onClick={sendShow}
          disabled={selectedShow === null || status !== 'active'}
        >
          {selectedShow !== null ? 'Update Lightshow' : 'Select A Lightshow'}
        </button>
      </div> */}
    </div>
  );
}
