import { createContext, ReactNode, useState } from 'react';

export type TokenState = {
  token: string;
  issued: number;
};

export type tTokenContext = [TokenState, (state: TokenState) => void];

export const TokenContext = createContext<tTokenContext>([
  {
    token: '',
    issued: 0,
  },
  () => true,
]);

const TokenProvider = ({ children }: { children: ReactNode }) => {
  const token = useState({ token: '', issued: 0 });

  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
};

export default TokenProvider;